import styled from "styled-components";

import {
  colors,
  sizes,
  breakpoints,
  gridContainer,
  textLeftGrid,
  Legend,
  textRightGrid,
} from "../models";

export const GridContainer = styled.div`
  ${gridContainer}
`;

export const LeftTextWrapper = styled.div`
  ${textLeftGrid}
`;

export const RightTextWrapper = styled.div`
  ${textRightGrid}
`;

export const Overlay = styled.div`
  display: none;
  width: 50%;
  height: 100vh;
  position: fixed;
  z-index: 1;
  pointer-events: none;
  background: linear-gradient(
    90deg,
    #f9f9f9 35.94%,
    rgba(252, 252, 252, 0.6) 74.48%,
    rgba(255, 255, 255, 0) 100%
  );
  @media (min-width: ${breakpoints.desktopS}px) {
    display: block;
  }
`;

export const ScreenHeight = styled.div`
  height: 100vh;
`;

export const BigHeight = styled.div`
  height: ${sizes.extremehuge}px;
`;
