import * as d3 from "d3";

interface DataProps {
  tagebau: string;
  revier: string;
  jahr_abgebaggert: [] | string;
  jahr_umgesiedelt_aufgeloest: [] | string;
  coordinates: any;
  value: number;
  children: {
    ortschaft: string;
    devastiert: string;
  }[];
}

const calculateOffsetData = (data: any, map: any) => {
  const calculateOffset = (
    calculatedCoordinates: number[],
    tagebauCoordinates: any
  ) => {
    const tagebauCoordinatesProjection = map?.project(tagebauCoordinates);
    const offsetX = calculatedCoordinates[0] - tagebauCoordinatesProjection.x;
    const offsetY = calculatedCoordinates[1] - tagebauCoordinatesProjection.y;
    return [offsetX, offsetY];
  };

  const createData = (data: any) => {
    const dataObject: Object[] = [];
    const radius: number = 11;
    data.map((feature: DataProps, featureIndex: number) => {
      const tagebauten: Object[] = feature.children.map((d) => ({
        data: d,
        r: radius,
      }));
      const simulation = d3
        .forceSimulation()
        .nodes(tagebauten)
        .force("collision", d3.forceCollide().radius(radius + 1))
        .force("x", d3.forceX(map?.project(feature.coordinates).x).strength(1))
        .force("y", d3.forceY(map?.project(feature.coordinates).y).strength(1))
        .tick(200);
      tagebauten.map((tagebau: any, tagebauIndex: number) => {
        const offset = calculateOffset(
          [tagebau.x, tagebau.y],
          feature.coordinates
        );
        const currentFeature: Object = {
          tagebau: feature.tagebau,
          revier: feature.revier,
          jahr_abgebaggert: tagebau.data.jahr_abgebaggert,
          jahr_umgesiedelt_aufgeloest: tagebau.data.jahr_umgesiedelt_aufgeloest,
          tagebauCoordinates: feature.coordinates,
          ortschaft: tagebau.data.ortschaft,
          devastiert: tagebau.data.devastiert,
          offsetX: offset[0],
          offsetY: offset[1],
        };
        dataObject.push(currentFeature);
      });
    });
    return dataObject;
  };
  return createData(data);
};

export default calculateOffsetData;
