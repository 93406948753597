import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import {
  colors,
  sizes,
  breakpoints,
  TooltipHeadline,
  TooltipBody,
  fonts,
} from "../../models";

const TooltipTitle = styled.div`
  ${TooltipHeadline};
`;

const TooltipText = styled.div`
  ${TooltipBody}
`;

const TooltipTextCategory = styled.span`
  font-weight: ${fonts.weightSemiBold};
`;

const TooltipBox = styled.div<any>`
  display: ${(props) => (props.visible ? "block" : "none")};
  left: ${(props) => `${props.position[0]}px`};
  top: ${(props) => `${props.position[1]}px`};
  position: absolute;
  background: ${colors.whiteTransparent};
  padding: ${sizes.fine}px ${sizes.base}px ${sizes.base}px ${sizes.base}px;
  border-radius: 4px;
  width: max-content;
  text-align: left;
  max-width: 150px;
  transform: translate(-50%, calc(-100% - ${sizes.base}px));

  @media (min-width: ${breakpoints.tabletL}px) {
    max-width: 200px;
  }

  @media (min-width: ${breakpoints.desktopL}px) {
    max-width: 270px;
  }
`;

const Tooltip = ({ data, showTooltip, tooltipPosition }: any) => {
  const getYearDescription = (dataEntry: any) => {
    return (
      <>
        {/* umgesiedelt info */}
        {dataEntry.jahr_umgesiedelt_aufgeloest &&
          dataEntry.jahr_umgesiedelt_aufgeloest.length === 1 &&
          dataEntry.jahr_umgesiedelt_aufgeloest[0].length === 1 && (
            <div>
              <TooltipTextCategory>umgesiedelt: </TooltipTextCategory>
              {dataEntry.jahr_umgesiedelt_aufgeloest[0]}
            </div>
          )}
        {dataEntry.jahr_umgesiedelt_aufgeloest &&
          dataEntry.jahr_umgesiedelt_aufgeloest.length === 1 &&
          dataEntry.jahr_umgesiedelt_aufgeloest[0].length === 2 && (
            <div>
              <TooltipTextCategory>umgesiedelt: </TooltipTextCategory>
              zwischen {dataEntry.jahr_umgesiedelt_aufgeloest[0][0]} und{" "}
              {dataEntry.jahr_umgesiedelt_aufgeloest[0][1]}
            </div>
          )}
        {dataEntry.jahr_umgesiedelt_aufgeloest &&
          dataEntry.jahr_umgesiedelt_aufgeloest.length === 2 &&
          dataEntry.jahr_umgesiedelt_aufgeloest[0].length === 1 &&
          dataEntry.jahr_umgesiedelt_aufgeloest[1].length === 1 && (
            <div>
              <TooltipTextCategory>umgesiedelt: </TooltipTextCategory>
              {dataEntry.jahr_umgesiedelt_aufgeloest[0][0]} und{" "}
              {dataEntry.jahr_umgesiedelt_aufgeloest[1][0]}
            </div>
          )}
        {dataEntry.jahr_umgesiedelt_aufgeloest &&
          dataEntry.jahr_umgesiedelt_aufgeloest.length === 2 &&
          dataEntry.jahr_umgesiedelt_aufgeloest[0].length === 2 &&
          dataEntry.jahr_umgesiedelt_aufgeloest[1].length === 1 && (
            <div>
              <TooltipTextCategory>umgesiedelt: </TooltipTextCategory>
              zwischen {dataEntry.jahr_umgesiedelt_aufgeloest[0][0]} und{" "}
              {dataEntry.jahr_umgesiedelt_aufgeloest[0][1]} und{" "}
              {dataEntry.jahr_umgesiedelt_aufgeloest[1][0]}
            </div>
          )}
        {dataEntry.jahr_umgesiedelt_aufgeloest &&
          dataEntry.jahr_umgesiedelt_aufgeloest.length === 2 &&
          dataEntry.jahr_umgesiedelt_aufgeloest[0].length === 1 &&
          dataEntry.jahr_umgesiedelt_aufgeloest[1].length === 2 && (
            <div>
              <TooltipTextCategory>umgesiedelt: </TooltipTextCategory>
              {dataEntry.jahr_umgesiedelt_aufgeloest[0][0]} und zwischen{" "}
              {dataEntry.jahr_umgesiedelt_aufgeloest[1][1]} und{" "}
              {dataEntry.jahr_umgesiedelt_aufgeloest[1][2]}
            </div>
          )}
        {dataEntry.jahr_umgesiedelt_aufgeloest &&
          dataEntry.jahr_umgesiedelt_aufgeloest.length === 2 &&
          dataEntry.jahr_umgesiedelt_aufgeloest[0].length === 2 &&
          dataEntry.jahr_umgesiedelt_aufgeloest[1].length === 2 && (
            <div>
              <TooltipTextCategory>umgesiedelt: </TooltipTextCategory>
              zwischen {dataEntry.jahr_umgesiedelt_aufgeloest[0][0]} und{" "}
              {dataEntry.jahr_umgesiedelt_aufgeloest[0][1]} und zwischen{" "}
              {dataEntry.jahr_umgesiedelt_aufgeloest[1][1]} und{" "}
              {dataEntry.jahr_umgesiedelt_aufgeloest[1][2]}
            </div>
          )}
        {/* abgebaggert info */}
        {dataEntry.jahr_abgebaggert &&
          dataEntry.jahr_abgebaggert.length === 1 &&
          dataEntry.jahr_abgebaggert[0].length === 1 && (
            <div>
              <TooltipTextCategory>
                {dataEntry.devastiert} abgebaggert:{" "}
              </TooltipTextCategory>
              {dataEntry.jahr_abgebaggert[0]}
            </div>
          )}
        {dataEntry.jahr_abgebaggert &&
          dataEntry.jahr_abgebaggert.length === 1 &&
          dataEntry.jahr_abgebaggert[0].length === 2 && (
            <div>
              <TooltipTextCategory>
                {dataEntry.devastiert} abgebaggert:{" "}
              </TooltipTextCategory>
              zwischen {dataEntry.jahr_abgebaggert[0][0]} und{" "}
              {dataEntry.jahr_abgebaggert[0][1]}
            </div>
          )}
        {dataEntry.jahr_abgebaggert &&
          dataEntry.jahr_abgebaggert.length === 2 &&
          dataEntry.jahr_abgebaggert[0].length === 1 &&
          dataEntry.jahr_abgebaggert[1].length === 1 && (
            <div>
              <TooltipTextCategory>
                {dataEntry.devastiert} abgebaggert:{" "}
              </TooltipTextCategory>
              {dataEntry.jahr_abgebaggert[0][0]} und{" "}
              {dataEntry.jahr_abgebaggert[1][0]}
            </div>
          )}
        {dataEntry.jahr_abgebaggert &&
          dataEntry.jahr_abgebaggert.length === 2 &&
          dataEntry.jahr_abgebaggert[0].length === 2 &&
          dataEntry.jahr_abgebaggert[1].length === 1 && (
            <div>
              <TooltipTextCategory>
                {dataEntry.devastiert} abgebaggert:{" "}
              </TooltipTextCategory>
              zwischen {dataEntry.jahr_abgebaggert[0][0]} und{" "}
              {dataEntry.jahr_abgebaggert[0][1]} und{" "}
              {dataEntry.jahr_abgebaggert[1][0]}
            </div>
          )}
        {dataEntry.jahr_abgebaggert &&
          dataEntry.jahr_abgebaggert.length === 2 &&
          dataEntry.jahr_abgebaggert[0].length === 1 &&
          dataEntry.jahr_abgebaggert[1].length === 2 && (
            <div>
              <TooltipTextCategory>
                {dataEntry.devastiert} abgebaggert:{" "}
              </TooltipTextCategory>
              {dataEntry.jahr_abgebaggert[0][0]} und zwischen{" "}
              {dataEntry.jahr_abgebaggert[1][1]} und{" "}
              {dataEntry.jahr_abgebaggert[1][2]}
            </div>
          )}
        {dataEntry.jahr_abgebaggert &&
          dataEntry.jahr_abgebaggert.length === 2 &&
          dataEntry.jahr_abgebaggert[0].length === 2 &&
          dataEntry.jahr_abgebaggert[1].length === 2 && (
            <div>
              <TooltipTextCategory>
                {dataEntry.devastiert} abgebaggert:{" "}
              </TooltipTextCategory>
              zwischen {dataEntry.jahr_abgebaggert[0][0]} und{" "}
              {dataEntry.jahr_abgebaggert[0][1]} und zwischen{" "}
              {dataEntry.jahr_abgebaggert[1][1]} und{" "}
              {dataEntry.jahr_abgebaggert[1][2]}
            </div>
          )}
      </>
    );
  };

  return (
    <>
      {data && (
        <TooltipBox visible={showTooltip} position={tooltipPosition}>
          <TooltipTitle>{data.ortschaft}</TooltipTitle>
          <TooltipText>
            <TooltipTextCategory>Tagebau: </TooltipTextCategory>
            {data.tagebau}
            <br />
            <TooltipTextCategory>Revier: </TooltipTextCategory>
            {data.revier}
            <br />
            {getYearDescription(data)}
          </TooltipText>
        </TooltipBox>
      )}
    </>
  );
};

export default Tooltip;
