import * as React from "react";
import styled, { keyframes } from "styled-components";
import {
  H0,
  H4,
  Body,
  sizes,
  colors,
  breakpoints,
  gridMargin,
} from "../../models";
import { LeftTextWrapper, ScreenHeight } from "../basics";
import { MainText } from "../../content";

const TitleWrapper = styled.div`
  margin-top: 100dvh;
  transform: translateY(-100%);
  margin-left: -${gridMargin.smartphone}px;
  margin-right: -${gridMargin.smartphone}px;
  padding: ${gridMargin.smartphone}px;
  background: ${colors.whiteTransparent};
  backdrop-filter: blur(6px);
  border-radius: 5px;

  @media (min-width: ${breakpoints.desktopS}px) {
    margin-top: 28vh;
    transform: translateY(0);
    background: none;
    padding: 0;
  }
`;

const Title = styled.div`
  ${H0};
  margin-bottom: ${sizes.fine}px;
`;

const TitleAnimationContainer = styled.div`
  overflow: hidden;
  height: 1em;
`;

const titleAnimation = keyframes`
 0% { transform: translate(0,0) }
 10% { transform: translate(0,0) }
 15% { transform: translate(0,-1em) }
 35% { transform: translate(0,-1em) }
 40% { transform: translate(0,-2em) }
 60% { transform: translate(0,-2em) }
 65% { transform: translate(0,-3em) }
 85% { transform: translate(0,-3em) }
 90% { transform: translate(0,-4em) }
 100% { transform: translate(0,-4em) }
`;

const TitleTransformer = styled.div`
  animation-name: ${titleAnimation};
  animation-direction: alternate;
  animation-duration: 8s;
  animation-iteration-count: infinite;
`;

const Subtitle = styled.div`
  ${H4};
  max-width: 450px;
`;

const IntroText = styled.div`
  ${Body};
  background: ${colors.whiteTransparent};
  backdrop-filter: blur(6px);
  margin-left: -${gridMargin.smartphone}px;
  margin-right: -${gridMargin.smartphone}px;
  padding: ${gridMargin.smartphone}px ${gridMargin.smartphone}px 0
    ${gridMargin.smartphone}px;
  border-radius: 5px;

  p {
    margin: 0;
    padding-bottom: ${sizes.petite}px;
  }

  @media (min-width: ${breakpoints.desktopS}px) {
    background: none;
  }
`;

const HeroSection = () => {
  return (
    <LeftTextWrapper>
      <TitleWrapper>
        <Title>
          <div>Warum</div>
          <TitleAnimationContainer>
            <TitleTransformer>
              <div>Schleenhain</div>
              <div>Rusendorf</div>
              <div>Spenrath</div>
              <div>Deumen</div>
              <div>Streckau</div>
              <div>Großdeuben</div>
            </TitleTransformer>
          </TitleAnimationContainer>
          <div>verschwand</div>
        </Title>
        <Subtitle>
          Eine kurze Geschichte über den Tagebau in Deutschland und seine Opfer.
          <br />
          <sup style={{ verticalAlign: "top" }}>(Stand 30.5.2022)</sup>
        </Subtitle>
      </TitleWrapper>
      <ScreenHeight />
      <IntroText>{MainText[0].text}</IntroText>
    </LeftTextWrapper>
  );
};

export default HeroSection;
