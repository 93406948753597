import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import tagebau from "../../data/tagebau_packs.json";
import OrtschaftMarkers from "./ortschaft_markers";
import TagebauMarkers from "./tagebau_markers";
import Tooltip from "./tooltip";
import useWindowDimensions from "../../util";
import { MainText } from "../../content";
import { colors } from "../../models";
import * as d3 from "d3";

import calculateOffsetData from "./calculate_offset";

const FixedMap = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  z-index: 0;
`;

mapboxgl.accessToken =
  "pk.eyJ1IjoiaG9uaWdtaXRvZSIsImEiOiJja3g1emlpa2kwNTE4Mm5vMDd4b3ZlY245In0.XhnoyYipXmdgL2mAeo9V4Q";

const MapboxMapStandard = ({
  coordinates,
  step,
  storyVisible,
  setPaddingLeft,
  zoom,
  setZoom,
  setChapter,
  reloadAfterInactive,
  setReloadAfterInactive,
}: any) => {
  const mapContainer = useRef<any>(null);
  const map = useRef<any>(null);
  const [lng, setLng] = useState(10.879);
  const [lat, setLat] = useState(51.329);
  const [offsetData, setOffsetData] = useState<any>([]);
  const [highlight, setHighlight] = useState<string[]>([""]);
  const [highlightTagebau, setHighlightTagebau] = useState<string>("");
  const [highlightRevier, setHighlightRevier] = useState<string>("");
  const [activeData, setActiveData] = useState<any>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState<number[]>([0, 0]);

  const { height, width } = useWindowDimensions();

  const calculatePaddingLeft = () => {
    const isDesktop = width >= 1200;
    if (isDesktop) {
      return width * 0.5;
    }
  };

  const zoomMultiplicator = d3
    .scaleLinear()
    .domain([320, 1200, 1440, 1920, 2500])
    .range([0.88, 1, 1, 1.05, 1.05]);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/honigmitoe/cl3n28467000o15mm19m0yro8",
      center: [lng, lat],
      zoom: zoom * zoomMultiplicator(width),
      maxZoom: 13,
      minZoom: 4.3,
      maxBounds: [
        [-15, 40], // [west, south]
        [38, 58], // [east, north]
      ],
    });
    setPaddingLeft(calculatePaddingLeft());
    map.current.jumpTo({
      padding: {
        left: calculatePaddingLeft(),
      },
    });
    setOffsetData(calculateOffsetData(tagebau, map.current)); // calculate new data with d3 simulation
  });

  useEffect(() => {
    // reload map
    if (!map.current) return;
    setPaddingLeft(calculatePaddingLeft());
    map.current.jumpTo({
      center: [10.879, 51.329],
      zoom: 5.1,
      speed: 3,
      essential: true,
      padding: {
        left: calculatePaddingLeft(),
      },
    });
    setReloadAfterInactive(false);
  }, [reloadAfterInactive]);

  useEffect(() => {
    if (!map.current) return;
    if (storyVisible) {
      setPaddingLeft(calculatePaddingLeft());
      map.current.flyTo({
        padding: {
          left: calculatePaddingLeft(),
        },
      });
    } else {
      setPaddingLeft(0);
      map.current.flyTo({
        padding: {
          left: 0,
        },
      });
    }
  }, [storyVisible]);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  }, [zoom]);

  useEffect(() => {
    // handle change of story text
    if (!map.current) return;
    if (!MainText) return;
    const coordinateData = MainText.filter((d) => d.step === step)[0];
    setHighlight(coordinateData.highlight);
    setHighlightRevier(coordinateData.highlightRevier);
    setHighlightTagebau(coordinateData.highlightTagebau);
    setChapter(coordinateData.chapter);
    map.current.flyTo({
      center: coordinateData.coordinates,
      zoom: coordinateData.zoom * zoomMultiplicator(width),
      speed: coordinateData.speed,
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    });
  }, [coordinates]);

  const handleMouseMove = (event: any, dataInput: number, radius: number) => {
    const parent = event.target.parentElement.parentElement;
    const svg = d3.select(parent).select("svg");
    svg.attr("width", radius * 2 + 5).attr("height", radius * 2 + 5);
    svg.select("circle").attr("fill-opacity", 1);
    setTooltipPosition([event.clientX, event.clientY]);
    setActiveData(dataInput);
    setShowTooltip(true);
  };

  const handleMouseLeave = (event: any, radius: number) => {
    const parent = event.target.parentElement.parentElement;
    const svg = d3.select(parent).select("svg");
    svg.attr("width", radius * 2).attr("height", radius * 2);
    svg.select("circle").attr("fill-opacity", 0.5);
    setShowTooltip(false);
  };

  return (
    <FixedMap>
      <MapContainer ref={mapContainer} className="map-container">
        {map.current && (
          <>
            <TagebauMarkers
              map={map.current}
              tagebauten={tagebau}
              zoom={zoom}
              highlightRevier={highlightRevier}
              highlightTagebau={highlightTagebau}
            />
            <OrtschaftMarkers
              map={map.current}
              places={offsetData}
              zoom={zoom}
              highlight={highlight}
              handleMouseMove={handleMouseMove}
              handleMouseLeave={handleMouseLeave}
            />
          </>
        )}
      </MapContainer>
      <Tooltip
        data={activeData}
        showTooltip={showTooltip}
        tooltipPosition={tooltipPosition}
      />
    </FixedMap>
  );
};

export default MapboxMapStandard;
