import React, { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import styled from "styled-components";

import { colors, strokes, hausPaths } from "../../models";

interface MarkerProps {
  map: any;
  data: any;
  offsetX: number;
  offsetY: number;
  circleColor: any;
  circleOpacity: number;
  zoom: number;
  handleMouseMove: any;
  handleMouseLeave: any;
  visibilityChange: number;
}

interface MarkersProps {
  map: any;
  places: any;
  zoom: number;
  highlight: string[];
  handleMouseMove: any;
  handleMouseLeave: any;
}

const MarkerWrapper = styled.div`
  cursor: default;
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};
  z-index: 100;
`;

const Marker = ({
  map,
  data,
  offsetX,
  offsetY,
  circleColor,
  circleOpacity,
  zoom,
  handleMouseMove,
  handleMouseLeave,
  visibilityChange,
}: MarkerProps) => {
  const markerRef = useRef<any>(null);

  useEffect(() => {
    console.log("render small markers");
    const marker = new mapboxgl.Marker(markerRef.current, {
      offset: [offsetX, offsetY],
    })
      .setLngLat(data.tagebauCoordinates)
      .addTo(map);

    return () => {
      marker.remove();
    };
  }, [visibilityChange]);

  const createSvg = () => {
    const visibility = zoom >= 10 ? "visible" : "hidden";
    const radius: number = 10;
    const haus: string =
      data.devastiert === "teilweise" ? hausPaths.half : hausPaths.full;
    const threatened: boolean = data.devastiert === "bedroht" ? true : false;
    return (
      <svg
        width={radius * 2}
        height={radius * 2}
        viewBox={`0 0 ${radius * 2} ${radius * 2}`}
        visibility={visibility}
        onMouseMove={(event) => handleMouseMove(event, data, radius)}
        onMouseOut={(event) => handleMouseLeave(event, radius)}
      >
        <circle
          cx={radius}
          cy={radius}
          r={radius}
          fill={circleColor}
          fillOpacity={circleOpacity}
        />
        <polygon
          points={haus}
          fill={threatened ? colors.transparent : colors.white}
        />
        <polygon
          points={hausPaths.full}
          fill={colors.white}
          fillOpacity={0.2}
          stroke={threatened ? colors.white : colors.black}
          strokeWidth={strokes.base}
        />
      </svg>
    );
  };

  return (
    <>
      <MarkerWrapper ref={markerRef} visible={zoom >= 10}>
        {createSvg()}
      </MarkerWrapper>
    </>
  );
};

const OrtschaftMarkers = ({
  map,
  places,
  zoom,
  highlight,
  handleMouseMove,
  handleMouseLeave,
}: MarkersProps) => {
  const createMarker = () => {
    const offsetMultiplikator: number = zoom >= 10 ? 1 : 0;
    return (
      <>
        {places.map((place: any, placeIndex: number) => {
          const offsetX: number = place.offsetX * offsetMultiplikator;
          const offsetY: number = place.offsetY * offsetMultiplikator;
          const circleColor: string | unknown =
            highlight.includes(place.ortschaft) ||
            (highlight.includes("alle Espenhain") &&
              place.tagebau === "Tagebau Espenhain")
              ? colors.highlight
              : colors.black;
          const circleOpacity: number =
            circleColor === colors.highlight ? 0.8 : 0.5;
          return (
            <Marker
              key={`${placeIndex}`}
              map={map}
              data={place}
              offsetX={offsetX}
              offsetY={offsetY}
              circleColor={circleColor}
              circleOpacity={circleOpacity}
              zoom={zoom}
              handleMouseMove={handleMouseMove}
              handleMouseLeave={handleMouseLeave}
              visibilityChange={offsetMultiplikator}
            />
          );
        })}
      </>
    );
  };

  return <>{places && zoom && createMarker()}</>;
};

export default OrtschaftMarkers;
