import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Legend,
  colors,
  sizes,
  radiusRange,
  strokes,
  hausPaths,
  breakpoints,
} from "../../models";

const LegendBoxWrapper = styled.div`
  height: 100vh;
  display: ${(props) => (props.visible ? "none" : "flex")};
  transition: display 0.5s ease-out;
  justify-content: center;
  align-items: flex-end;
`;

const LegendBox = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.whiteTransparent};
  backdrop-filter: blur(6px);
  padding: 7px 12px 3px 12px;
  border-radius: 4px;
  border: ${strokes.thin}px solid ${colors.black};
  margin-bottom: ${sizes.hefty}px;

  @media (min-width: ${breakpoints.desktopS}px) {
    margin-bottom: ${sizes.compact}px;
  }
`;

const LegendElement = styled.div`
  ${Legend};
  display: flex;
  align-items: center;
  padding-bottom: ${sizes.micro}px;
`;

const LegendSmallCircleElement = styled.div`
  ${Legend};
  display: flex;
  align-items: center;
`;

const LegendText = styled.span`
  padding-left: ${sizes.micro}px;
`;

const LegendOverlay = ({ storyVisible, paddingLeft, zoom }: any) => {
  const [smallZoomView, setSmallZoomView] = useState<boolean>(true);
  const [hidden, setHidden] = useState<boolean>(false);

  useEffect(() => {
    if (zoom <= 10) {
      setSmallZoomView(true);
    } else {
      setSmallZoomView(false);
    }
  }, [zoom]);

  useEffect(() => {
    setHidden(!storyVisible); // when story is not visible legend should be hidden
  }, [storyVisible]);

  const createTagebauSizeLegendElement = () => {
    const bigTagebauCircleDiameter = radiusRange.tagebau[1] * 2;
    const smallTagebauCircleDiameter = radiusRange.tagebau[0] * 2;
    const labelPaddingLeft = 8;
    return (
      <>
        <LegendSmallCircleElement>
          <svg
            width={
              bigTagebauCircleDiameter + strokes.base * 2 + labelPaddingLeft
            }
            height={smallTagebauCircleDiameter + strokes.base * 2}
          >
            <circle
              id={"small-tagebau"}
              cx={bigTagebauCircleDiameter / 2 + strokes.base}
              cy={smallTagebauCircleDiameter / 2 + strokes.base}
              r={smallTagebauCircleDiameter / 2}
              fill={"none"}
              strokeWidth={strokes.base}
              stroke={colors.black}
            />
            <line
              x1={
                bigTagebauCircleDiameter / 2 +
                smallTagebauCircleDiameter / 2 +
                strokes.base
              }
              y1={smallTagebauCircleDiameter / 2 + strokes.base}
              x2={
                bigTagebauCircleDiameter + strokes.base + labelPaddingLeft - 2
              }
              y2={smallTagebauCircleDiameter / 2 + strokes.base}
              strokeWidth={strokes.base}
              stroke={colors.black}
            />
          </svg>

          <LegendText style={{ paddingLeft: 0 }}>
            eine zerstörte Ortschaft
          </LegendText>
        </LegendSmallCircleElement>
        <LegendElement>
          <svg
            width={
              bigTagebauCircleDiameter + strokes.base * 2 + labelPaddingLeft
            }
            height={bigTagebauCircleDiameter + strokes.base * 2}
          >
            <circle
              id={"big-tagebau"}
              cx={bigTagebauCircleDiameter / 2 + strokes.base}
              cy={bigTagebauCircleDiameter / 2 + strokes.base}
              r={bigTagebauCircleDiameter / 2}
              fill={"none"}
              strokeWidth={strokes.base}
              stroke={colors.black}
            />
            <line
              x1={bigTagebauCircleDiameter + strokes.base}
              y1={bigTagebauCircleDiameter / 2 + strokes.base}
              x2={
                bigTagebauCircleDiameter + strokes.base + labelPaddingLeft - 2
              }
              y2={bigTagebauCircleDiameter / 2 + strokes.base}
              strokeWidth={strokes.base}
              stroke={colors.black}
            />
          </svg>

          <LegendText style={{ paddingLeft: 0 }}>
            20 zerstörte Ortschaften
          </LegendText>
        </LegendElement>
      </>
    );
  };

  const createColoredCircleLegendElement = (
    fillColor: string,
    strokeColor: string,
    fillOpacity: number,
    text: string
  ) => {
    const radius = 7;
    return (
      <LegendElement>
        <svg
          width={radius * 2 + strokes.base * 2}
          height={radius * 2 + strokes.base * 2}
        >
          <circle
            cx={radius + strokes.base}
            cy={radius + strokes.base}
            r={radius}
            fill={fillColor}
            fillOpacity={fillOpacity}
            stroke={strokeColor}
            strokeWidth={strokes.base}
          />
        </svg>
        <LegendText>{text}</LegendText>
      </LegendElement>
    );
  };

  const createHouseElement = (devastiert: string) => {
    const radius: number = 10;
    const haus: string =
      devastiert === "teilweise zerstört" ? hausPaths.half : hausPaths.full;
    return (
      <LegendElement>
        <svg width={radius * 2} height={radius * 2}>
          <circle
            cx={radius}
            cy={radius}
            r={radius}
            fill={colors.black}
            fillOpacity={0.5}
          />
          <polygon
            points={haus}
            fill={colors.white}
            visibility={devastiert === "bedroht" ? "hidden" : "visible"}
          />
          <polygon
            points={hausPaths.full}
            fill={colors.white}
            fillOpacity={0.2}
            stroke={devastiert === "bedroht" ? colors.white : colors.black}
            strokeWidth={strokes.base}
          />
        </svg>
        <LegendText>{devastiert}e Ortschaft</LegendText>
      </LegendElement>
    );
  };

  const createTagebauHeuteLegendElement = () => {
    const radius: number = 10;
    return (
      <LegendElement>
        <svg width={radius * 2} height={radius * 2}>
          <pattern
            id="diagonalHatch"
            patternUnits="userSpaceOnUse"
            width="4"
            height="4"
          >
            <path
              d={`M-1,1l2,-2M0,4 l4,-4M3,5l2,-2`}
              stroke={colors.black}
              strokeWidth={strokes.base}
            />
          </pattern>
          <circle
            cx={radius}
            cy={radius}
            r={radius}
            fill={"url(#diagonalHatch)"}
          />
        </svg>
        <LegendText>aktives Tagebaufeld</LegendText>
      </LegendElement>
    );
  };

  const createLegendBox = () => {
    return (
      <>
        {smallZoomView ? (
          <LegendBox>
            {createTagebauSizeLegendElement()}{" "}
            {createColoredCircleLegendElement(
              colors.black,
              colors.black,
              0.5,
              "Tagebau"
            )}
          </LegendBox>
        ) : (
          <LegendBox>
            {createHouseElement("komplett zerstört")}
            {createHouseElement("teilweise zerstört")}
            {createHouseElement("bedroht")}
            {createTagebauHeuteLegendElement()}
          </LegendBox>
        )}
      </>
    );
  };
  return (
    <LegendBoxWrapper style={{ paddingLeft: paddingLeft }} visible={hidden}>
      {createLegendBox()}
    </LegendBoxWrapper>
  );
};

export default LegendOverlay;
