import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { strokes, colors, sizes, breakpoints, Overline } from "../../models";

interface StepProps {
  coordinates: [];
}

const StepperWrapper = styled.div`
  height: 100vh;
  width: 100%;
  position: absolute;
  display: none;
  flex-direction: column;
  transition: display 1s ease-out;
  justify-content: end;
  align-items: flex-end;
  bottom: ${sizes.hefty}px;

  @media (min-width: ${breakpoints.desktopS}px) {
    display: ${(props) => (props.visible ? "flex" : "none")};
    justify-content: center;
    margin: 0;
    bottom: ${sizes.compact}px;
  }
`;

const LegendElement = styled.div`
  ${Overline};
  display: flex;
  align-items: center;
  padding-bottom: ${sizes.micro}px;
  margin: 0 ${sizes.fine}px 0 0;
`;

const LegendText = styled.span`
  padding-right: ${sizes.micro}px;
  display: none;
  transition: display 1s ease-out;

  @media (min-width: ${breakpoints.desktopS}px) {
    display: ${(props) => (props.visible ? "block" : "none")};
  }
`;

const Stepper = ({ currentChapter, storyVisible }: any) => {
  const chapters = ["Einleitung", "Reviere", "Dörfer", "Umwelt", "Kämpfe"];

  const createSteps = (chapters: string[]) => {
    return (
      <>
        {chapters.map((chapter) => {
          const radius = 6;
          const visible = currentChapter === chapter;
          return (
            <LegendElement>
              <LegendText visible={visible}>{chapter}</LegendText>
              <svg
                width={radius * 2 + strokes.base * 2}
                height={radius * 2 + strokes.base * 2}
              >
                <circle
                  cx={radius + strokes.base}
                  cy={radius + strokes.base}
                  r={radius}
                  fill={"none"}
                  strokeWidth={strokes.base}
                  stroke={colors.black}
                />
                <circle
                  cx={radius + strokes.base}
                  cy={radius + strokes.base}
                  r={radius - 2}
                  fill={colors.black}
                  visibility={visible ? "visible" : "hidden"}
                />
              </svg>
            </LegendElement>
          );
        })}
      </>
    );
  };
  return (
    <StepperWrapper visible={storyVisible}>
      {createSteps(chapters)}
    </StepperWrapper>
  );
};

export default Stepper;
