import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { TooltipHeadline, colors, sizes, strokes, fonts } from "../../models";
import Cross from "../../svg/cross.svg";

const Button = styled.button`
  display: flex;
  color: ${colors.black};
  justify-content: center;
  align-items: center;
  font-family: ${fonts.base};
  font-weight: ${fonts.weightSemiBold};
  font-size: 20px;
  position: fixed;
  top: ${sizes.fine}px;
  right: ${sizes.fine}px;
  width: 32px;
  height: 32px;
  border-radius: 300px;
  background: ${colors.whiteTransparent};
  border: ${strokes.base}px solid ${colors.black};
  z-index: 300;
  &:hover {
    cursor: pointer;
  }
`;

const ToMapButton = ({ methodologyVisible, setMethodologyVisible }: any) => {
  const [buttonText, setButtonText] = useState<any>(<Cross />);

  useEffect(() => {
    if (methodologyVisible) {
      setButtonText(<Cross />);
    } else {
      setButtonText(`?`);
    }
  }, [methodologyVisible]);

  const handleClick = () => {
    if (methodologyVisible) {
      setMethodologyVisible(false);
    } else {
      setMethodologyVisible(true);
    }
  };

  return (
    <>
      <Button onClick={handleClick}>{buttonText}</Button>
    </>
  );
};

export default ToMapButton;
