import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { H2, Body, sizes, breakpoints, colors, gridMargin } from "../../models";
import { LeftTextWrapper, ScreenHeight, BigHeight } from "../basics";
import { useIntersection } from "../../hooks";
import { MainText } from "../../content";

const TextContainer = styled.div`
  background: ${colors.whiteTransparent};
  margin-left: -${gridMargin.smartphone}px;
  margin-right: -${gridMargin.smartphone}px;
  padding: 8px ${gridMargin.smartphone}px 0 ${gridMargin.smartphone}px;
  backdrop-filter: blur(6px);
  border-radius: 5px;

  @media (min-width: ${breakpoints.desktopS}px) {
    background: none;
    padding: 0;
  }
`;

const StepHeadline = styled.div`
  ${H2}
`;

const BodyText = styled.div`
  ${Body};
  p {
    margin: 0;
    padding: ${sizes.fine}px 0 ${sizes.petite}px 0;
  }
`;

const MainSection = ({ setGlobalCoordinates, setGlobalStep }: any) => {
  const scrollerContainerRef = useRef(null);

  useIntersection(
    scrollerContainerRef,
    "#waypoint",
    (entry: any) => {
      if (entry.intersectionRatio === 1) {
        const coordinateArrayOfStrings =
          entry.target.dataset.coordinates.split(",");
        const coordinateArrayOfFloat = coordinateArrayOfStrings.map(function (
          x: string
        ) {
          return parseFloat(x);
        });
        setGlobalCoordinates(coordinateArrayOfFloat);
        setGlobalStep(parseInt(entry.target.dataset.step));
      }
    },
    { threshold: 1, rootMargin: "0px" }
  );

  return (
    <LeftTextWrapper ref={scrollerContainerRef}>
      {MainText &&
        MainText.map((entry: any) => {
          return entry.id != "einleitung" ? (
            <>
              <ScreenHeight />
              <TextContainer>
                <StepHeadline
                  id="waypoint"
                  data-step={entry.step}
                  data-coordinates={entry.coordinates}
                >
                  {entry.headline}
                </StepHeadline>
                <BodyText>{entry.text}</BodyText>
              </TextContainer>
              <span
                id="waypoint"
                data-step={entry.step}
                data-coordinates={entry.coordinates}
              ></span>
            </>
          ) : (
            <span
              id="waypoint"
              data-step={entry.step}
              data-coordinates={entry.coordinates}
            ></span>
          );
        })}
    </LeftTextWrapper>
  );
};

export default MainSection;
