import * as React from "react";
import { Story } from "../components";
import "../css/global-styles.css";
import favicon from "../images/icon.png";
import ogImage from "../images/ogImage.png";
import { Helmet } from "react-helmet";

const IndexPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" href={favicon} sizes="32x32" />
        <meta property="og:title" content="Verschwundene Dörfer" />
        <meta property="og:image" content={ogImage} />
      </Helmet>
      <main>
        <Story />
      </main>
    </>
  );
};

export default IndexPage;
