import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import styled from "styled-components";
import * as d3 from "d3";

import { colors, strokes, radiusRange } from "../../models";

interface MarkerProps {
  map: any;
  data: any;
  radius: number;
  fillOpacity: number;
  circleColor: string;
}

interface MarkersProps {
  map: any;
  tagebauten: any;
  zoom: number;
  highlightRevier: string;
  highlightTagebau: string;
}

const Marker = ({
  map,
  data,
  radius,
  fillOpacity,
  circleColor,
}: MarkerProps) => {
  const markerRef = useRef<any>(null);

  useEffect(() => {
    const marker = new mapboxgl.Marker(markerRef.current)
      .setLngLat(data.coordinates)
      .addTo(map);

    return () => {
      marker.remove();
    };
  }, [radius]);

  const createSvg = () => {
    const stroke: number = strokes.base;
    return (
      <svg width={radius * 2 + stroke * 2} height={radius * 2 + stroke * 2}>
        <circle
          cx={radius + stroke}
          cy={radius + stroke}
          r={radius}
          fill={circleColor}
          fillOpacity={fillOpacity}
          stroke={circleColor}
          strokeWidth={stroke}
        />
      </svg>
    );
  };

  return (
    <>
      <div ref={markerRef}>{createSvg()}</div>
    </>
  );
};

const TagebauMarkers = ({
  map,
  tagebauten,
  zoom,
  highlightRevier,
  highlightTagebau,
}: MarkersProps) => {
  const [tagebauMultiplicator, setTagebauMultiplicator] = useState(1);
  const [fillOpacity, setFillOpacity] = useState(0.5);

  useEffect(() => {
    if (zoom >= 10 && tagebauMultiplicator === 1) {
      setTagebauMultiplicator(4);
    } else if (zoom < 10 && tagebauMultiplicator === 4) {
      setTagebauMultiplicator(1);
    }
    if (zoom >= 10 && fillOpacity === 0.5) {
      setFillOpacity(0.1);
    } else if (zoom < 10 && fillOpacity === 0.1) {
      setFillOpacity(0.5);
    }
  }, [zoom]);

  const createMarker = () => {
    const radiusScale = d3
      .scaleSqrt()
      .domain([1, 20])
      .range(radiusRange.tagebau);
    return (
      <>
        {tagebauten.map((tagebau: any, placeIndex: number) => {
          const radius: number =
            radiusScale(tagebau.children.length) * tagebauMultiplicator;
          const circleColor =
            highlightRevier === tagebau.revier ||
            highlightTagebau === tagebau.tagebau
              ? colors.highlight
              : colors.black;
          return (
            <Marker
              key={`${placeIndex}`}
              map={map}
              data={tagebau}
              radius={radius}
              fillOpacity={fillOpacity}
              circleColor={circleColor}
            />
          );
        })}
      </>
    );
  };

  return <>{tagebauten && createMarker()}</>;
};

export default TagebauMarkers;
