import * as React from "react";
import styled from "styled-components";
import { H0, H2, Body, sizes, colors, breakpoints } from "../../models";
import { LeftTextWrapper, ScreenHeight } from "../basics";
import { MainText } from "../../content";

const MethodologyWrapper = styled.div<any>`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 200;
  background: ${colors.whiteTransparent};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};
  transition: opacity 0.2s ease-out;
`;

const MethodologyTextBox = styled.div`
  background: ${colors.white};
  max-width: 90vw;
  height: 80vh;
  border: 1px solid ${colors.black};
  padding: ${sizes.fine}px ${sizes.petite}px;
  overflow-y: scroll;

  @media (min-width: ${breakpoints.tabletL}px) {
    max-width: 600px;
  }
  @media (min-width: ${breakpoints.desktopS}px) {
    max-width: 600px;
  }
`;

const Headline = styled.div`
  ${H2};
  margin-bottom: ${sizes.fine}px;
`;

const Text = styled.div`
  ${Body};
  margin-bottom: ${sizes.petite}px;
`;

const ExternalLink = styled.a`
  text-decoration: none;
  color: ${colors.black};
  border-bottom: 2px solid ${colors.black};

  transition: border-bottom 0.1s ease-out;
  &:hover {
    border-bottom: 0px solid ${colors.black};
  }
`;

const Methodology = ({ visible }: any) => {
  return (
    <MethodologyWrapper visible={visible}>
      <MethodologyTextBox>
        <Headline>Datenerhebung</Headline>
        <Text>
          Die Daten, welche für die Darstellung der Ortschaften verwendet
          wurden, stammen aus zwei Wikipedia Listen. Eine davon dokumentiert die{" "}
          <ExternalLink
            href="https://de.wikipedia.org/wiki/Liste_abgebaggerter_Ortschaften"
            target={"_blank"}
          >
            abgebaggerten Ortschaften in gesamt Deutschland.
          </ExternalLink>{" "}
          Die andere deckt nur das{" "}
          <ExternalLink
            href="https://de.wikipedia.org/wiki/Liste_der_abgebrochenen_Orte_im_Lausitzer_Kohlerevier"
            target={"_blank"}
          >
            Lausitzer Braunkohlerevier
          </ExternalLink>{" "}
          ab. Die Einträge wurden mithilfe eines Scripts in eine einheitliche
          Tabelle umgewandelt. Teilweise wurden dabei Begrifflichkeiten
          vereinheitlicht. So wurden beispielsweise „abgebaggert“, „abgerissen“,
          „devastiert“ und „überbaggert“ als Synonyme zusammengefasst. Leider
          existierte zum Zeitpunkt der Datenerhebung keine andere umfangreiche
          und frei zugängliche Sammlung von umgesiedelten und zerstörten
          Ortschaften in Deutschland. Die Verzeichnung der aktiven Tagebaufelder
          auf der Karte basiert auf Open Street Map Daten.
        </Text>
        <Headline>Visualisierung</Headline>
        <Text>
          Die genaue Verortung der abgebaggerten Ortschaften war nicht für alle
          Ortschaften möglich. Deshalb wurden die Ortschaften anhand der
          Koordinaten der Tagebaue, denen sie weichen mussten verortet. Die
          Platzierung der Ortschaften innerhalb eines Tagebau-Kreises auf der
          Karte hat dabei keine Bedeutung. Die Visualisierung der Karte wurde
          mithilfe von Mapbox und React umgesetzt.
        </Text>
      </MethodologyTextBox>
    </MethodologyWrapper>
  );
};

export default Methodology;
