import React from "react";
import styled from "styled-components";
import { colors, H2, TooltipBody, sizes, breakpoints } from "../../models";
import { GridContainer, LeftTextWrapper, RightTextWrapper } from "../basics";
import FHP from "../../svg/fhp.svg";

const FooterContainer = styled.div`
  position: relative;
  background: ${colors.black};
  z-index: 11;
  color: ${colors.white};
  padding: 5vh 0 20vh 0;
`;

const SourceHeadline = styled.div`
  ${H2};
  margin: ${sizes.hefty}px 0 ${sizes.petite}px 0;
`;

const Source = styled.div`
  ${TooltipBody};
  padding: ${sizes.fine / 2}px 0;
`;

const ExternalLink = styled.a`
  text-decoration: none;
  color: ${colors.white};
  border-bottom: 2px solid ${colors.white};

  transition: border-bottom 0.1s ease-out;
  &:hover {
    border-bottom: 0px solid ${colors.white};
  }
`;

const Sticky = styled.div`
  position: sticky;
  top: 0;
  margin-top: ${sizes.hefty}px;

  @media (min-width: ${breakpoints.desktopS}px) {
    top: 0;
    margin-top: 0;
  }
`;

const FHPLogoContainer = styled.div`
  margin-top: ${sizes.hefty}px;
  max-width: 350px;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <GridContainer>
        <LeftTextWrapper>
          <SourceHeadline>Quellen</SourceHeadline>
        </LeftTextWrapper>
      </GridContainer>
      <GridContainer>
        <LeftTextWrapper>
          <Source>
            [1]{" "}
            <ExternalLink
              href="https://www.boell.de/de/2018/12/27/floeze-gruben-schaechte-geschichte-der-braunkohle-deutschland"
              target={"_blank"}
            >
              Baum, Carla (27.12.2018): Flöze, Gruben, Schächte – Geschichte der
              Braunkohle in Deutschland,
            </ExternalLink>{" "}
            [abgerufen am 02.07.2022].
          </Source>
          <Source>
            [2]{" "}
            <ExternalLink
              href="https://www.archiv-verschwundene-orte.de/de/ausstellung/themen/umsiedlung/67878"
              target={"_blank"}
            >
              Themen: Umsiedlung. (o.D.).
            </ExternalLink>{" "}
            [abgerufen am 02.07.2022].
          </Source>
          <Source>
            [3]{" "}
            <ExternalLink
              href="http://garzweiler.com/wie-funktioniert-eine-umsiedlung/"
              target={"_blank"}
            >
              Wie funktioniert eine Umsiedlung. (o.D.).
            </ExternalLink>{" "}
            [abgerufen am 02.07.2022].
          </Source>
          <Source>
            [4]{" "}
            <ExternalLink
              href="https://braunkohle.de/braunkohle-in-deutschland/uebersicht-und-geschichte-der-reviere/ "
              target={"_blank"}
            >
              Übersicht und Geschichte der Reviere. (o.D.).
            </ExternalLink>{" "}
            [abgerufen am 02.07.2022].
          </Source>
          <Source>
            [5]{" "}
            <ExternalLink
              href="https://www.outdooractive.com/de/poi/ostthueringen/gedenkstein-ort-rusendorf/50549388/"
              target={"_blank"}
            >
              Gehrt, Steffen (15.09.2020): Gedenkstein Ort Rusendorf,
            </ExternalLink>{" "}
            [abgerufen am 02.07.2022].
          </Source>
          <Source>
            [6]{" "}
            <ExternalLink
              href="https://www.mitteldeutsches-magazin.de/ns-zwangsarbeit-espenhain"
              target={"_blank"}
            >
              Haufe, Martin; Baumert, Martin (o.D.): Dunkle Geschichte erinnern:
              NS-Zwangsarbeit in Espenhain,
            </ExternalLink>{" "}
            [abgerufen am 18.08.2022].
          </Source>
          <Source>
            [7]{" "}
            <ExternalLink
              href="https://de.wikipedia.org/wiki/Tagebau_Espenhain"
              target={"_blank"}
            >
              Lektorat (05.03.2022): Tagebau Espenhain,
            </ExternalLink>{" "}
            [abgerufen am 18.08.2022].
          </Source>
          <Source>
            [8]{" "}
            <ExternalLink
              href="https://www.mdr.de/geschichte/ddr/wirtschaft/braunkohle-lausitz-abbaggern-revier-bergbau-tagebau-100.html"
              target={"_blank"}
            >
              Abgebaggerte Dörfer: Die Folgen der Braunkohleförderung in der
              DDR. (17.03.2022).
            </ExternalLink>{" "}
            [abgerufen am 02.07.2022].
          </Source>
          <Source>
            [9]{" "}
            <ExternalLink
              href="https://de.wikipedia.org/wiki/Cospuden"
              target={"_blank"}
            >
              Lektorat (01.01.2021): Cospuden,
            </ExternalLink>{" "}
            [abgerufen am 18.08.2022].
          </Source>
          <Source>
            [10]{" "}
            <ExternalLink
              href="https://de.wikipedia.org/wiki/Tagebau_Hambach"
              target={"_blank"}
            >
              Lektorat (20.06.2022): Tagebau Hambach,
            </ExternalLink>{" "}
            [abgerufen am 18.08.2022].
          </Source>
          <Source>
            [11]{" "}
            <ExternalLink
              href="https://www.bund-nrw.de/fileadmin/nrw/dokumente/braunkohle/2018_02_Tagebau_Hambach_40_Jahre_Widerstand.pdf"
              target={"_blank"}
            >
              Bund für Umwelt und Naturschutz Deutschland LV NRW e.V. (o.D.): 40
              Jahre Widerstand gegen den Tagebau Hambach,
            </ExternalLink>{" "}
            [abgerufen am 18.08.2022].
          </Source>
          <Source>
            [12]{" "}
            <ExternalLink
              href="https://taz.de/Geschichte-der-Braunkohleproteste/!5540202/"
              target={"_blank"}
            >
              Kriener, Manfred (10.10.2018): Die Wühlerei und der versaute
              Betrieb,
            </ExternalLink>{" "}
            [abgerufen am 18.08.2022].
          </Source>
          <Source>
            [13]{" "}
            <ExternalLink
              href="https://www.alle-doerfer-bleiben.de/doerfer/"
              target={"_blank"}
            >
              Dörfer. (o.D.).
            </ExternalLink>{" "}
            [abgerufen am 18.08.2022].
          </Source>
          <Source>
            [14]{" "}
            <ExternalLink
              href="https://www.spiegel.de/politik/deutschland/garzweiler-ende-gelaende-aktivisten-besetzen-kohlebunker-in-garzweiler-a-244ffb9b-1b98-4771-ba53-ffc20976f372"
              target={"_blank"}
            >
              Köppe, Julia (26.09.2020): Aktivisten besetzen Kohlebunker in
              Garzweiler,
            </ExternalLink>{" "}
            [abgerufen am 18.08.2022].
          </Source>
          <SourceHeadline>Bildquellen</SourceHeadline>
          <Source>
            [Bild 1]{" "}
            <ExternalLink
              href="https://commons.wikimedia.org/wiki/File:Rusendorf.jpg"
              target={"_blank"}
            >
              Own work, Gedenkstein für das abgebaggerte Rusendorf, Tnemtsoni /
              CC-BY-SA 3.0,
            </ExternalLink>{" "}
            [abgerufen am 18.08.2022].
          </Source>
          <Source>
            [Bild 2]{" "}
            <ExternalLink
              href="https://commons.wikimedia.org/wiki/File:Bundesarchiv_Bild_183-1990-0713B-021,_Espenhain,_Braunkohleveredelungswerk.jpg"
              target={"_blank"}
            >
              Bundesarchiv, Bild 183-1990-0713B-021 / Grubitzsch (geb. Raphael),
              Waltraud / CC-BY-SA 3.0,
            </ExternalLink>{" "}
            [abgerufen am 18.08.2022].
          </Source>
          <Source>
            [Bild 3]{" "}
            <ExternalLink
              href="https://de.wikipedia.org/wiki/Datei:Bundesarchiv_Bild_183-1990-0402-020,_Protest_gegen_Erweiterung_des_Tagebaus_Cospuden.jpg"
              target={"_blank"}
            >
              Bundesarchiv, Bild 183-1990-0402-020 / Kluge, Wolfgang / CC-BY-SA
              3.0,
            </ExternalLink>{" "}
            [abgerufen am 18.08.2022].
          </Source>
        </LeftTextWrapper>
        <RightTextWrapper>
          <Sticky>
            <Source>
              Konzept, Datenaufbereitung, Gestaltung und Programmierung
              von&nbsp;
              <ExternalLink href="https://honigmitoe.de/" target={"_blank"}>
                Tim&nbsp;Hönig
              </ExternalLink>
            </Source>
            <FHPLogoContainer>
              <FHP width="100%" />
            </FHPLogoContainer>
            <Source>
              Das Projekt entstand im Seminar “Karten der Macht. Macht der
              Karten.” bei Prof.&nbsp;Dr.&nbsp;Sebastian&nbsp;Meier im
              Sommersemester 2022
            </Source>
          </Sticky>
        </RightTextWrapper>
      </GridContainer>
    </FooterContainer>
  );
};

export default Footer;
