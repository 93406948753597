import React from "react";
import styled from "styled-components";
import { sizes } from "../models";

interface MarkerProps {
  src: string;
}

const VideoContainer = styled.div`
  margin-top: ${sizes.compact}px;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

const VideoEmbed = ({ src }: MarkerProps) => (
  <VideoContainer>
    <iframe
      width="853"
      height="480"
      src={src}
      frameBorder="0"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Demonstration Garzweiler 2019"
    />
  </VideoContainer>
);

export default VideoEmbed;
