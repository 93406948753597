import React from "react";
import styled from "styled-components";
import Lottie from "react-lottie";
import { colors, sizes, Legend, sourceNumber, breakpoints } from "../models";
import rusendorf from "../images/rusendorf.jpg";
import espenhain from "../images/espenhain.jpg";
import cospuden from "../images/cospuden.jpg";
import VideoEmbed from "../components/youtube-embed";
import hambach from "../videos/hambach-small.mp4";

const Highlight = styled.span`
  background-color: ${colors.highlightText};
  padding: 0 5px;
`;

const StoryImage = styled.img`
  max-width: 90%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);

  transition: filter 1s ease-out;
  transition: -webkit-filter 1s ease-out;
  &:hover {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
  }

  @media (min-width: ${breakpoints.desktopS}px) {
    margin-top: ${sizes.compact}px;
  }
`;

const StoryVideo = styled.video`
  @media (min-width: ${breakpoints.desktopS}px) {
    margin-top: ${sizes.compact}px;
  }
`;

const ImageDescription = styled.div`
  ${Legend}
  margin: ${sizes.micro}px 0 ${sizes.petite}px 0;

  @media (min-width: ${breakpoints.desktopS}px) {
    margin-top: ${sizes.micro}px 0 ${sizes.hefty}px 0;
  }
`;

const ExternalLink = styled.a`
  text-decoration: none;
  color: ${colors.black};
  border-bottom: 2px solid ${colors.black};

  transition: border-bottom 0.1s ease-out;
  &:hover {
    border-bottom: 0px solid ${colors.black};
  }
`;

const SourceNumberLink = styled.a`
  ${sourceNumber};
  color: ${colors.black};
  text-decoration: none;
`;

const SourceNumber = styled.sup`
  vertical-align: top;
`;
const ImageNumber = styled.span``;

export const MainText = [
  {
    step: 0,
    id: "einleitung",
    chapter: "Einleitung",
    coordinates: [10.879, 51.329],
    zoom: 5.1,
    speed: 1,
    highlightRevier: "",
    highlightTagebau: "",
    highlight: [""],
    headline: "",
    text: (
      <>
        <p>
          Schon seit dem 17. Jahrhundert entstehen in Deutschland Gruben für den
          Kohleabbau.
          <SourceNumberLink
            href="https://www.boell.de/de/2018/12/27/floeze-gruben-schaechte-geschichte-der-braunkohle-deutschland"
            target={"_blank"}
          >
            <SourceNumber>[1]</SourceNumber>
          </SourceNumberLink>{" "}
          Im Zuge der Industrialisierung erfuhr der Tagebau einen regelrechten
          ‚Boom‘ und die Gruben wuchsen so stark, dass dafür die ersten Dörfer
          weichen mussten.
          <SourceNumberLink
            href="https://www.archiv-verschwundene-orte.de/de/ausstellung/themen/umsiedlung/67878"
            target={"_blank"}
          >
            <SourceNumber>[2]</SourceNumber>
          </SourceNumberLink>{" "}
          Die Zerstörung von Ortschaften und die Umsiedelung ihrer
          Bewohner*innen sind bis heute gängige Praxis.
        </p>
        <p>
          Dabei sind die Bewohner*innen gezwungen ihre Grundstücke von
          Gutachter*innen bewerten zu lassen und sie an den Kohlekonzern zu
          verkaufen. Weigern sie sich, können sie zwangsenteignet werden.
          <SourceNumberLink
            href="http://garzweiler.com/wie-funktioniert-eine-umsiedlung/"
            target={"_blank"}
          >
            <SourceNumber>[3]</SourceNumber>
          </SourceNumberLink>{" "}
          Dieses Projekt macht die teilweise und vollständig abgebaggerten
          Ortschaften und die zugehörigen Tagebaue wieder sichtbar. Beginnen wir
          mit einer Übersicht der Reviere.
        </p>
      </>
    ),
  },
  {
    step: 1,
    id: "reviere",
    chapter: "Reviere",
    coordinates: [10.879, 51.329],
    zoom: 5.1,
    speed: 1,
    highlightRevier: "",
    highlightTagebau: "",
    highlight: [""],
    headline: "Die Reviere",
    text: (
      <>
        <p>
          In der Geschichte des Braunkohletagebaus entstanden in Deutschland
          mindestens 78 Tagebaue, für die Ortschaften weichen mussten. Sie sind
          auf der Karte verzeichnet. Lass uns die drei größten Tagebaureviere,
          in denen bis heute Kohle abgebaut wird, genauer betrachten:
        </p>
      </>
    ),
  },
  {
    step: 2,
    id: "reviere",
    chapter: "Reviere",
    coordinates: [6.76, 51.03],
    zoom: 8,
    speed: 1,
    highlightRevier: "Rheinisches Braunkohlerevier",
    highlightTagebau: "",
    highlight: [""],
    headline: "",
    text: (
      <>
        <p>
          Das <Highlight>Rheinische Revier</Highlight> im Städtedreieck Aachen,
          Köln, Mönchengladbach wird heute ausschließlich von RWE betrieben. Das
          Revier beinhaltet die drei großen noch aktiven Tagebaue Garzweiler,
          Hambach und Inden.
          <SourceNumberLink
            href="https://braunkohle.de/braunkohle-in-deutschland/uebersicht-und-geschichte-der-reviere/"
            target={"_blank"}
          >
            <SourceNumber>[4]</SourceNumber>
          </SourceNumberLink>{" "}
        </p>
      </>
    ),
  },
  {
    step: 3,
    id: "reviere",
    chapter: "Reviere",
    coordinates: [12.36, 51.45],
    zoom: 8,
    speed: 1,
    highlightRevier: "Mitteldeutsches Braunkohlerevier",
    highlightTagebau: "",
    highlight: [""],
    headline: "",
    text: (
      <>
        <p>
          Im <Highlight>Mitteldeutschen Braunkohlerevier</Highlight> entstand
          bereits 1698 die erste Kohlegrube in Müncheln/Braunsbedra. Insgesamt
          wurden hier über 120 Ortschaften teilweise oder vollständig
          abgebaggert. Heute betreiben MIBRAG und ROMONTA insgesamt noch drei
          Tagebaue.
          <SourceNumberLink
            href="https://braunkohle.de/braunkohle-in-deutschland/uebersicht-und-geschichte-der-reviere/"
            target={"_blank"}
          >
            <SourceNumber>[4]</SourceNumber>
          </SourceNumberLink>{" "}
        </p>
      </>
    ),
  },
  {
    step: 4,
    id: "reviere",
    chapter: "Reviere",
    coordinates: [14.45, 51.42],
    zoom: 8,
    speed: 0.6,
    highlightRevier: "Lausitzer Braunkohlerevier",
    highlightTagebau: "",
    highlight: [""],
    headline: "",
    text: (
      <>
        <p>
          Die aktiven Tagebaue im <Highlight>Lausitzer Revier</Highlight> im
          südosten Brandenburgs werden von LEAG betrieben.
          <SourceNumberLink
            href="https://braunkohle.de/braunkohle-in-deutschland/uebersicht-und-geschichte-der-reviere/"
            target={"_blank"}
          >
            <SourceNumber>[4]</SourceNumber>
          </SourceNumberLink>{" "}
        </p>
      </>
    ),
  },
  {
    step: 5,
    id: "reviere",
    chapter: "Reviere",
    coordinates: [10.879, 51.329],
    zoom: 5.1,
    speed: 1,
    highlightRevier: "",
    highlightTagebau: "",
    highlight: [""],
    headline: "",
    text: (
      <>
        <p>
          Neben diesen Revieren zeigt die Karte auch Tagebaue aus dem
          Helmstedter Revier, dem Oberpfälzer Revier und Tagebaue der SDAG
          Wismut, die allesamt nicht mehr in Betrieb sind.
        </p>
      </>
    ),
  },
  {
    step: 6,
    id: "erste",
    chapter: "Dörfer",
    coordinates: [12.2906, 51.0676],
    zoom: 13,
    speed: 1.2,
    highlightRevier: "",
    highlightTagebau: "",
    highlight: ["Rusendorf"],
    headline: "Erste Umsiedelungen",
    text: (
      <>
        <p>
          Zu den ersten Ortschaften, die von einer Umsiedelung betroffen waren,
          gehört <Highlight>Rusendorf</Highlight> im Mitteldeutschten
          Braunkohlerevier. Das Dorf wurde zwischen 1927 und 1933 für den
          Tagebau Phönix-Falkenhain abgebaggert. Ca. 150 Bewohner*innen wurden
          umgesiedelt. Bereits zu Beginn des 20. Jahrhunderts wurden rings um
          Rusendorf Tagebaue errichtet, wodurch das Dorf nur noch über Umwege zu
          erreichen war. Nachdem 1927 die ersten Familien das Dorf verließen,
          wurde die Gemeinde 1932 nach Falkenhain eingemeindet.
          <SourceNumberLink
            href="https://www.outdooractive.com/de/poi/ostthueringen/gedenkstein-ort-rusendorf/50549388/"
            target={"_blank"}
          >
            <SourceNumber>[5]</SourceNumber>
          </SourceNumberLink>{" "}
        </p>
        <StoryImage src={rusendorf} alt="rusendorf" />
        <ImageDescription>
          Gedenkstein in der Nähe der alten Ortslage{" "}
          <SourceNumberLink
            href="https://commons.wikimedia.org/wiki/File:Rusendorf.jpg"
            target={"_blank"}
          >
            <ImageNumber>[Bild 1]</ImageNumber>
          </SourceNumberLink>{" "}
        </ImageDescription>
        <p>
          Heute befindet sich an der Stelle des Dorfes der Rusendorfer See der
          im Zuge der Renaturierung des Gebiets entstand. Ein Gedenkstein in der
          Nähe des alten Ortsflurs erinnert an das Dorf.
        </p>
      </>
    ),
  },
  {
    step: 7,
    id: "espenhain",
    chapter: "Dörfer",
    coordinates: [12.422780636464037, 51.241359132241286],
    zoom: 12,
    speed: 0.6,
    highlightRevier: "",
    highlightTagebau: "Tagebau Espenhain",
    highlight: [""],
    headline: "Zwangsarbeit in Espenhain",
    text: (
      <>
        <p>
          Ab 1937 entstand in{" "}
          <Highlight>Espenhain ein Braunkohletagebau</Highlight> um die
          Kriegsmarine der Nazis mit Schwer-, Heiz- und Dieselöl zu versorgen.
          Ab 1938 kamen die ersten Zwangsarbeiter*innen nach Espenhain. Fortan
          gab es große Unterschiede in der Behandlung der Arbeiter*innen.{" "}
        </p>
        <p>
          Die rassistische Hierarchisierung stellte deutsche Staatsangehörige an
          oberste Stelle, danach kamen Menschen aus verbündeten Staaten,
          Kriegsgefangene und Arbeitskräfte aus dem Westen und schließlich
          Menschen aus Ostmittel- und Osteuropa sowie sowjetische
          Kriegsgefangene und Arbeitserziehungslagerhäftlinge. Diese arbeiteten
          häufig unter lebensbedrohlichen Bedingungen.
          <SourceNumberLink
            href="https://www.mitteldeutsches-magazin.de/ns-zwangsarbeit-espenhain"
            target={"_blank"}
          >
            <SourceNumber>[6]</SourceNumber>
          </SourceNumberLink>{" "}
        </p>
        <StoryImage src={espenhain} alt="espenhain" />
        <ImageDescription>
          Tagebau Espenhain{" "}
          <SourceNumberLink
            href="https://commons.wikimedia.org/wiki/File:Bundesarchiv_Bild_183-1990-0713B-021,_Espenhain,_Braunkohleveredelungswerk.jpg"
            target={"_blank"}
          >
            <ImageNumber>[Bild 2]</ImageNumber>
          </SourceNumberLink>{" "}
        </ImageDescription>
        <p>
          In den Wohnlagern rund um Espenhain wurden Frauen aus Frankreich und
          Polen zur Prostitution gezwungen. Insgesamt verstarben mindestens 285
          Menschen in den Lagern. Einen detaillierten Artikel über die
          Zwangsarbeit in Espenhain findest du{" "}
          <ExternalLink
            href="https://www.mitteldeutsches-magazin.de/ns-zwangsarbeit-espenhain"
            target={"_blank"}
          >
            hier
          </ExternalLink>
          . Der Tagebau wurde 1996 schließlich stillgelegt. Heute befindet sich
          im Restloch des Tagebaus der Markkleeberger und Störmthaler See.
          <SourceNumberLink
            href="https://de.wikipedia.org/wiki/Tagebau_Espenhainn"
            target={"_blank"}
          >
            <SourceNumber>[7]</SourceNumber>
          </SourceNumberLink>{" "}
        </p>
      </>
    ),
  },
  {
    step: 8,
    id: "ddr",
    chapter: "Dörfer",
    coordinates: [12.422780636464037, 51.241359132241286],
    zoom: 12,
    speed: 1.2,
    highlightRevier: "",
    highlightTagebau: "",
    highlight: ["alle Espenhain"],
    headline: "Tagebau in der DDR",
    text: (
      <>
        <p>
          Später wurde der Tagebau Espenhain für die DDR relevant. Wie im
          gesamten Mitteldeutschen und Lausitzer Revier wurde der Kohleabbau
          ausgeweitet und zahlreiche Dörfer umgesiedelt. Alle{" "}
          <Highlight>
            20 Ortschaften die dem Tagebau Espenhain zum Opfer fielen,
          </Highlight>{" "}
          wurden zu DDR-Zeiten abgebaggert. Da viele Bürger*innen von der Arbeit
          in den Kohlegruben abhängig waren, regte sich nur selten Widerstand
          gegen die Umsiedelungen. Ende der 80er Jahre wurden Umweltthemen
          jedoch relevanter und der Druck auf die Regierung wuchs.
          <SourceNumberLink
            href="https://www.mdr.de/geschichte/ddr/wirtschaft/braunkohle-lausitz-abbaggern-revier-bergbau-tagebau-100.html"
            target={"_blank"}
          >
            <SourceNumber>[8]</SourceNumber>
          </SourceNumberLink>{" "}
        </p>
      </>
    ),
  },
  {
    step: 9,
    id: "cospuden",
    chapter: "Dörfer",
    coordinates: [12.335169, 51.265381],
    zoom: 12,
    speed: 0.3,
    highlightRevier: "",
    highlightTagebau: "Tagebau Cospuden",
    highlight: [""],
    headline: "",
    text: (
      <>
        <StoryImage src={cospuden} alt="Sternmarsch Cospuden" />
        <ImageDescription>
          Sternmarsch gegen den Tagebau Cospuden 1990{" "}
          <SourceNumberLink
            href="https://de.wikipedia.org/wiki/Datei:Bundesarchiv_Bild_183-1990-0402-020,_Protest_gegen_Erweiterung_des_Tagebaus_Cospuden.jpg"
            target={"_blank"}
          >
            <ImageNumber>[Bild 3]</ImageNumber>
          </SourceNumberLink>{" "}
        </ImageDescription>
        <p>
          So konnte Beispielsweise der Ausbau des benachbarten{" "}
          <Highlight>Tagebaus Cospuden</Highlight> kurz nach Mauerfall durch die
          Widerstandsbewegung gestoppt werden. Bei einem Sternmarsch 1990 kamen
          über 10.000 Menschen zusammen.
          <SourceNumberLink
            href="https://de.wikipedia.org/wiki/Cospuden"
            target={"_blank"}
          >
            <SourceNumber>[9]</SourceNumber>
          </SourceNumberLink>{" "}
        </p>
      </>
    ),
  },
  {
    step: 10,
    id: "hambach",
    chapter: "Umwelt",
    coordinates: [6.53, 50.910833],
    zoom: 11.5,
    speed: 0.8,
    highlightRevier: "",
    highlightTagebau: "Tagebau Hambach",
    highlight: [""],
    headline: "Nebelschwaden und Mondlandschaften",
    text: (
      <>
        <p>
          Die Ausdehnungen von Braunkohletagebauen und die damit einhergehende
          Umweltzerstörungen sind oftmals gewaltig. Ein eindrucksvolles Beispiel
          dafür ist der <Highlight>Tagebau Hambach</Highlight> im Rheinischen
          Revier. Er besitzt die größte Braunkohlegrube Europas und erstreckt
          sich heute über rund 44km².
          <SourceNumberLink
            href="https://de.wikipedia.org/wiki/Tagebau_Hambach"
            target={"_blank"}
          >
            <SourceNumber>[10]</SourceNumber>
          </SourceNumberLink>{" "}
          Das entspricht der Fläche von über 6.000 Fussballfeldern.{" "}
        </p>
        <StoryVideo width="100%" height="100%" loop autoPlay muted>
          <source src={hambach} />
        </StoryVideo>
        <ImageDescription>
          Tagebau Hambach zwischen 1985 und 2020
        </ImageDescription>
        <p>
          {" "}
          Seit sich 1978 die ‚Hambachgruppe‘ gründete gibt es immer wieder
          Protestbewegungen gegen den Tagebau Hambach. Diese richteten sich
          sowohl gegen die Umsiedelung von Dörfern als auch gegen die
          Umweltschäden durch CO², Feinstaub, wasserwirtschaflich-ökologische
          Langzeitfolgen und die Abholzung von Waldflächen.
          <SourceNumberLink
            href="https://www.bund-nrw.de/fileadmin/nrw/dokumente/braunkohle/2018_02_Tagebau_Hambach_40_Jahre_Widerstand.pdf"
            target={"_blank"}
          >
            <SourceNumber>[11]</SourceNumber>
          </SourceNumberLink>{" "}
          Warum insbesondere Braunkohle so schlecht für das Klima ist, kannst du{" "}
          <ExternalLink
            href="https://www.quarks.de/technik/energie/warum-braunkohle-besonders-schlecht-fuers-klima-ist/"
            target={"_blank"}
          >
            hier
          </ExternalLink>{" "}
          nachlesen.
        </p>
      </>
    ),
  },
  {
    step: 11,
    id: "garzweiler",
    chapter: "Kämpfe",
    coordinates: [6.502039, 51.063893],
    zoom: 12,
    speed: 0.5,
    highlightRevier: "",
    highlightTagebau: "",
    highlight: [
      "Berverath",
      "Keyenberg",
      "Kuckum",
      "Lützerath",
      "Oberwestrich",
      "Unterwestrich",
      "Holzweiler",
      "Kaulhausen",
      "Wanlo",
    ],
    headline: "Aktuelle Kämpfe",
    text: (
      <>
        <p>
          Der Kampf gegen Umsiedlungen und die Klimaschäden durch Tagebau hat in
          Deutschland Tradition und beginnt schon Ende des 19. Jahrhunderts.
          <SourceNumberLink
            href="https://taz.de/Geschichte-der-Braunkohleproteste/!5540202/"
            target={"_blank"}
          >
            <SourceNumber>[12]</SourceNumber>
          </SourceNumberLink>{" "}
          Im Zuge der Klimakrise erhöht sich der Druck auf die Politik den Abbau
          von Kohle zu stoppen. Neue Bewegungen wie ‚Fridays for Future‘ treten
          auf die Bildfläche. Einen besonderen Fokus auf den Kampf gegen die
          Umsiedlung von Dörfern durch Tagebau setzt das Bündnis ‚Alle Dörfer
          bleiben‘. Auf ihrer Website befindet sich eine Übersicht über die
          derzeit umkämpften Dörfer.
          <SourceNumberLink
            href="https://www.alle-doerfer-bleiben.de/doerfer/"
            target={"_blank"}
          >
            <SourceNumber>[13]</SourceNumber>
          </SourceNumberLink>{" "}
          Darunter befinden sich auch{" "}
          <Highlight>
            neun Dörfer die aktuell vom Tagebau Garzweiler oder von dessen
            Grubenrandlage bedroht sind.
          </Highlight>{" "}
          Damit wurde er in der Vergangenheit häufiger Ziel von Protestaktionen.
          So versuchten Demonstrant*innen bei einer Aktion der Bewegung ‚Ende
          Gelände‘ 2019 den Tagebau zu besetzen.
        </p>
        <VideoEmbed src="https://www.youtube.com/embed/4pSh6Hp_DBE" />
        <ImageDescription>
          360-Grad Video von der Ende-Gelände Aktion 2019
        </ImageDescription>
        <p>
          Bei der Aktion im Folgejahr waren die Demonstrant*innen bei der
          Besetzung erfolgreich und blockierten mehrere Kohlebagger.
          <SourceNumberLink
            href="https://www.spiegel.de/politik/deutschland/garzweiler-ende-gelaende-aktivisten-besetzen-kohlebunker-in-garzweiler-a-244ffb9b-1b98-4771-ba53-ffc20976f372"
            target={"_blank"}
          >
            <SourceNumber>[14]</SourceNumber>
          </SourceNumberLink>{" "}
          Informationen zur Teilnahme an Protestaktionen findest du auf den
          Websites der genannten Gruppen.
        </p>
      </>
    ),
  },
];
