import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Legend, colors } from "../models";

const HintWrapper = styled.div`
  ${Legend};
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${colors.whiteTransparent};
  color: ${colors.black};
  padding: 16px 20px;
  z-index: 999;
  text-align: center;
`;

const ResetHint = ({ showResetHint, setShowResetHint }: any) => {
  const [timer, setTimer] = useState(10);
  const previousShowResetHint = useRef(showResetHint);

  useEffect(() => {
    if (showResetHint && !previousShowResetHint.current) {
      setTimer(10); // Zurücksetzen des Timers beim Anzeigen des Hints
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(countdown);
    }
    previousShowResetHint.current = showResetHint;
  }, [showResetHint]);

  useEffect(() => {
    if (timer === 0) {
      setShowResetHint(false);
    }
  }, [timer, setShowResetHint]);

  if (!showResetHint) return null;

  return (
    <HintWrapper>
      Bitte bewegen Sie die Maus, um fortzufahren. <br />
      Seite wird in {timer} Sekunden zurückgesetzt.
    </HintWrapper>
  );
};

export default ResetHint;
