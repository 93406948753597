import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Legend, colors, sizes, strokes } from "../../models";

const Button = styled.button`
  ${Legend};
  color: ${colors.black};
  position: fixed;
  top: 0;
  border-width: 0;
  background: ${colors.whiteTransparent};
  padding: 0;
  border-bottom: ${strokes.big}px solid ${colors.black};
  z-index: 10;
  margin: ${sizes.fine}px 0 0 ${sizes.fine}px;
  &:hover {
    cursor: pointer;
  }
`;

const ToMapButton = ({ storyVisible, setStoryVisible }: any) => {
  const [buttonText, setButtonText] = useState<any>(
    `Karte selbst erkunden &#8594;`
  );

  useEffect(() => {
    if (storyVisible) {
      setButtonText(`Karte selbst erkunden &#8594;`);
    } else {
      setButtonText(`&#8592; zurück zur Story`);
    }
  }, [storyVisible]);

  const handleClick = () => {
    if (storyVisible) {
      setStoryVisible(false);
    } else {
      setStoryVisible(true);
    }
  };

  return (
    <>
      <Button
        onClick={handleClick}
        dangerouslySetInnerHTML={{ __html: buttonText }}
      ></Button>
    </>
  );
};

export default ToMapButton;
