import { css } from "styled-components";
import {
  fonts,
  colors,
  fontSizes,
  strokes,
  breakpoints,
  lineHeights,
} from "../models";
import { sizes } from "./constants";

export const baseFontProps = {
  "font-family": fonts.base,
  "font-weight": fonts.weightRegular,
  "font-size": fontSizes.base,
  "line-height": `${lineHeights.body}`,
};

export const condensedFontProps = {
  "font-family": fonts.base,
  "font-weight": fonts.weightCondensed,
  "font-size": fontSizes.base,
  "font-stretch": fonts.fontStretchCondensed,
  "line-height": `${lineHeights.small}`,
};

export const titleFontProps = {
  "font-family": fonts.title,
  "font-weight": fonts.weightRegular,
  "font-size": fontSizes.base,
  "text-transform": "uppercase",
};

// -03 macro
export const macroRegular = {
  ...baseFontProps,
  "font-size": fontSizes.macro,
};
export const macroBlack = {
  ...macroRegular,
  "font-weight": fonts.weightBlack,
};

// -02 micro
export const microRegular = {
  ...baseFontProps,
  "font-size": fontSizes.micro,
};

// -01 small
export const smallRegular = {
  ...baseFontProps,
  "font-size": fontSizes.small,
};

export const smallMedium = {
  ...smallRegular,
  "font-weight": `${fonts.weightMedium}`,
};

// 00 base
export const baseRegular = {
  ...baseFontProps,
};
export const baseMedium = {
  ...baseRegular,
  "font-weight": `${fonts.weightMedium}`,
};
export const baseSemiBold = {
  ...baseRegular,
  "font-weight": `${fonts.weightSemiBold}`,
};

// +01 midSize
export const midSizeRegular = {
  ...baseFontProps,
  "font-size": fontSizes.midSize,
};
export const midSizeSemiBold = {
  ...midSizeRegular,
  "font-weight": `${fonts.weightSemiBold}`,
};
export const midSizeBlack = {
  ...midSizeRegular,
  "font-weight": fonts.weightBlack,
};
export const midSizeBlackCondensed = {
  ...condensedFontProps,
  "font-size": fontSizes.midSize,
};

// +02 big
export const bigRegular = {
  ...baseFontProps,
  "font-size": fontSizes.big,
};
export const bigSemiBold = {
  ...bigRegular,
  "font-weight": fonts.weightSemiBold,
};
export const bigBlack = {
  ...bigRegular,
  "font-weight": fonts.weightBlack,
};
export const bigBlackCondensed = {
  ...condensedFontProps,
  "font-size": fontSizes.big,
};

// +03 large
export const largeRegular = {
  ...baseFontProps,
  "font-size": fontSizes.large,
};
export const largeBlack = {
  ...largeRegular,
  "font-weight": fonts.weightBlack,
};
export const largeBlackCondensed = {
  ...condensedFontProps,
  "font-size": fontSizes.large,
};

// +04 huge
export const hugeRegular = {
  ...baseFontProps,
  "font-size": fontSizes.huge,
};
export const hugeBlack = {
  ...hugeRegular,
  "font-weight": fonts.weightBlack,
};
export const hugeBlackCondensed = {
  ...condensedFontProps,
  "font-size": fontSizes.huge,
};

// +05 gigantic
export const giganticRegular = {
  ...baseFontProps,
  "font-size": fontSizes.gigantic,
};
export const giganticBlack = {
  ...giganticRegular,
  "font-weight": fonts.weightBlack,
};

// +06 mega
export const megaBlackCondensed = {
  ...titleFontProps,
  "font-size": fontSizes.mega,
  "line-height": "1em",
};

// STORY
/*
  {
    macro: "8.192px",
    micro: "10.24px",
    small: "12.8px",
    base: "16px",
    midSize: "20px",
    big: "25px",
    large: "31.25px",
    huge: "39.0625px",
    gigantic: "48.828125px"
    mega: "61.04px"
  }
*/

export const TooltipBody = css`
  ${smallMedium};
  @media (min-width: ${breakpoints.desktopS}px) {
    ${baseRegular};
  }
`;

export const TooltipHeadline = css`
  ${midSizeBlackCondensed};
  @media (min-width: ${breakpoints.desktopS}px) {
    ${bigBlackCondensed};
  }
`;

export const sourceNumber = css`
  ${microRegular};
  @media (min-width: ${breakpoints.desktopS}px) {
    ${smallRegular};
  }
`;

export const Legend = css`
  ${smallMedium};
  @media (min-width: ${breakpoints.desktopXL}px) {
    ${baseMedium};
  }
`;

export const Overline = css`
  ${smallMedium};
  text-transform: uppercase;
  letter-spacing: 0.04em;
  @media (min-width: ${breakpoints.desktopXL}px) {
    ${baseMedium};
  }
`;

export const Button = css`
  ${smallMedium};
  @media (min-width: ${breakpoints.desktopS}px) {
    ${baseMedium};
  }
`;

export const Body = css`
  ${baseRegular};
  @media (min-width: ${breakpoints.desktopS}px) {
    ${midSizeRegular};
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    ${bigRegular};
  }
`;

export const H4 = css`
  ${baseSemiBold};
  @media (min-width: ${breakpoints.tabletL}px) {
    ${midSizeSemiBold};
  }
`;

export const H2 = css`
  ${largeBlackCondensed};
  @media (min-width: ${breakpoints.desktopS}px) {
    ${hugeBlackCondensed};
  }
`;

export const H0 = css`
  ${megaBlackCondensed};
  @media (min-width: ${breakpoints.tabletL}px) {
    font-size: 75px;
    line-height: 75px;
  }
  @media (min-width: ${breakpoints.desktopXL}px) {
    font-size: 117px;
    line-height: 117px;
  }
`;
