import * as React from "react";
import styled from "styled-components";
import {
  H2,
  Body,
  Button,
  sizes,
  colors,
  gridMargin,
  breakpoints,
} from "../../models";
import { LeftTextWrapper, BigHeight, ScreenHeight } from "../basics";

const TextContainer = styled.div`
  background: ${colors.whiteTransparent};
  backdrop-filter: blur(6px);
  margin-left: -${gridMargin.smartphone}px;
  margin-right: -${gridMargin.smartphone}px;
  padding: ${gridMargin.smartphone}px ${gridMargin.smartphone}px
    ${sizes.petite}px ${gridMargin.smartphone}px;
  border-radius: 5px;

  @media (min-width: ${breakpoints.desktopS}px) {
    background: none;
    padding: 0;
  }
`;

const Headline = styled.div`
  ${H2}
`;

const IntroText = styled.div`
  ${Body};
  p {
    margin: ${sizes.fine}px 0 ${sizes.petite}px 0;
  }
`;

const DiscoverButton = styled.button`
  ${Button};
  border-width: 0;
  border-radius: 4px;
  background: ${colors.black};
  color: ${colors.white};
  padding: ${sizes.fine}px ${sizes.base}px ${sizes.fine}px ${sizes.base}px;
  z-index: 100;
  margin-top: ${sizes.petite}px;
  transition: background 0.3s ease-out;
  &:hover {
    background: ${colors.highlight};
    cursor: pointer;
  }
`;

const Discover = ({ storyVisible, setStoryVisible }: any) => {
  const handleClick = () => {
    if (storyVisible) {
      setStoryVisible(false);
    } else {
      setStoryVisible(true);
    }
  };

  return (
    <LeftTextWrapper>
      <ScreenHeight />
      <TextContainer>
        <Headline>Alle Dörfer</Headline>
        <IntroText>
          <p>
            Das war nur ein kleiner Einblick in die Geschichte des Deutschen
            Tagebaus. Wenn du erfahren möchtest welche weiteren Dörfer den
            Baggern weichen mussten, kannst du die Karte selbst erkunden.
          </p>
        </IntroText>
        <DiscoverButton onClick={handleClick}>Karte erkunden</DiscoverButton>
      </TextContainer>
      <BigHeight />
    </LeftTextWrapper>
  );
};

export default Discover;
