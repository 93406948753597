import React, { useState, useRef, useEffect } from "react";
import {
  Discover,
  HeroSection,
  MainSection,
  Footer,
  Methodology,
} from "./sections";
import styled from "styled-components";
import { Overlay, GridContainer } from "./basics";
import { MapboxMapStandard, LegendOverlay } from "./map";
import { ToMapButton, Stepper, ToMethodologyButton } from "./menu";
import ResetHint from "./reset-hint"; // Import der Reset-Hinweis-Komponente

const StoryWrapper = styled.div<any>`
  width: 100%;
  position: absolute;
  top: 0;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};
  transition: opacity 0.2s ease-out;
`;

const MenusWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  pointer-events: none;
`;

const Story = () => {
  const [coordinates, setCoordinates] = useState([10.879, 51.329]);
  const [step, setStep] = useState(0);
  const [storyVisible, setStoryVisible] = useState<boolean>(true);
  const [methodologyVisible, setMethodologyVisible] = useState<boolean>(false);
  const [paddingLeft, setPaddingLeft] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(5.1);
  const [chapter, setChapter] = useState<string>("Einleitung");
  const [reloadAfterInactive, setReloadAfterInactive] = useState(false);
  const [showResetHint, setShowResetHint] = useState(false);

  const resetTimeoutRef = useRef<NodeJS.Timeout>();
  const showResetHintTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const resetTimer = () => {
      if (resetTimeoutRef.current) {
        clearTimeout(resetTimeoutRef.current);
      }
      if (showResetHintTimeoutRef.current) {
        clearTimeout(showResetHintTimeoutRef.current);
      }

      setShowResetHint(false); // Versteckt den Hinweis, wenn der Benutzer interagiert

      resetTimeoutRef.current = setTimeout(() => {
        setShowResetHint(false);
        setReloadAfterInactive(true);
        setStep(1);
        setStoryVisible(true);
        setMethodologyVisible(false);
        setChapter("Einleitung");

        // Nach oben scrollen
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 20000); // 60 Sekunden Inaktivität

      // Hinweis 10 Sekunden vor dem Zurücksetzen anzeigen
      showResetHintTimeoutRef.current = setTimeout(() => {
        setShowResetHint(true);
      }, 10000);
    };

    const handleUserInteraction = () => {
      setShowResetHint(false);
      resetTimer();
    };

    document.addEventListener("mousemove", handleUserInteraction);
    document.addEventListener("mousedown", handleUserInteraction);
    window.addEventListener("scroll", handleUserInteraction);

    resetTimer(); // Initialen Timer starten

    return () => {
      document.removeEventListener("mousemove", handleUserInteraction);
      document.removeEventListener("mousedown", handleUserInteraction);
      window.removeEventListener("scroll", handleUserInteraction);
      if (resetTimeoutRef.current) {
        clearTimeout(resetTimeoutRef.current);
      }
      if (showResetHintTimeoutRef.current) {
        clearTimeout(showResetHintTimeoutRef.current);
      }
    };
  }, []);

  const handleCoordinatesChange = (coordinatesInput: []) => {
    setCoordinates(coordinatesInput);
  };

  const handleStepChange = (stepInput: number) => {
    setStep(stepInput);
  };

  const handleToMapButton = (buttonInput: boolean) => {
    setStoryVisible(buttonInput);
  };

  const handleToMethodologyButton = (buttonInput: boolean) => {
    setMethodologyVisible(buttonInput);
  };

  const handlePaddingLeftChange = (paddingInput: number) => {
    setPaddingLeft(paddingInput);
  };

  const handleZoomChange = (zoomInput: number) => {
    setZoom(zoomInput);
  };

  const handleChapterChange = (chapterInput: string) => {
    setChapter(chapterInput);
  };

  return (
    <>
      <ToMapButton
        storyVisible={storyVisible}
        setStoryVisible={handleToMapButton}
      />
      <ToMethodologyButton
        methodologyVisible={methodologyVisible}
        setMethodologyVisible={handleToMethodologyButton}
      />
      <Methodology visible={methodologyVisible} />
      <StoryWrapper visible={storyVisible}>
        <Overlay />
        <GridContainer>
          <HeroSection />
          <MainSection
            setGlobalStep={handleStepChange}
            setGlobalCoordinates={handleCoordinatesChange}
          />
          <Discover
            storyVisible={storyVisible}
            setStoryVisible={handleToMapButton}
          />
        </GridContainer>
        <Footer />
      </StoryWrapper>
      <MenusWrapper>
        <Stepper currentChapter={chapter} storyVisible={storyVisible} />
        <LegendOverlay
          storyVisible={storyVisible}
          paddingLeft={paddingLeft}
          zoom={zoom}
        />
      </MenusWrapper>
      <ResetHint
        showResetHint={showResetHint}
        setShowResetHint={setShowResetHint}
      />
      <MapboxMapStandard
        coordinates={coordinates}
        step={step}
        storyVisible={storyVisible}
        setStoryVisible={setStoryVisible}
        setPaddingLeft={handlePaddingLeftChange}
        zoom={zoom}
        setZoom={handleZoomChange}
        setChapter={handleChapterChange}
        reloadAfterInactive={reloadAfterInactive}
        setReloadAfterInactive={setReloadAfterInactive}
      />
    </>
  );
};

export default Story;
